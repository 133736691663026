import "../content/_loader.less";

import { BffClient } from "./bootstrap/bff";

void (async () => {
  const bffClient = new BffClient();
  const { user, csrfToken } = await bffClient.getUser();
  if (!user) {
    window.location.href = bffClient.getLoginUrl();
    return;
  }

  const { main } = await import("./main");
  await main({ user, csrfToken });
})();
